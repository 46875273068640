import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
	// Eko views
	PAYMENT_REMARK: lazy(() => import('../views/eko/master/payment_remark')),
	TAX_AP: lazy(() => import('../views/eko/invoice/tax_approval')),
	// Afif views
	PPN: lazy(() => import('../views/afif/master/ppn')),
	INV_AP_APPROVAL: lazy(() => import('../views/afif/invoice/ap_approval')),
	SAMPLE_REQUEST: lazy(() => import('../views/afif/product/SampleRequest')),
	AUTHORIZATION: lazy(() => import('../views/afif/setting/Authorization')),
	INV_DOC_RECEIPT: lazy(() => import('../views/bakti/DocumentReceipt')),
	// Rizky views
	Category: lazy(() => import('../views/rizky/Category')),
	Payment: lazy(() => import('../views/rizky/invoice/payment')),
};
const Invoice = {
	InvoiceVendor: lazy(() => import('../views/tyo/InvoiceVendor')),
};
const Master = {
	Service: lazy(() => import('../views/bakti/Service')),
	Instruction: lazy(() => import('../views/bakti/Instruction')),
	InstructionProductListing: lazy(() => import('../views/handri/ProductListing')),
	InstructionVendor: lazy(() => import('../views/bakti/InstructionView')),
};
const Product = {
	ProductCatalog: lazy(() => import('../views/sodiq/ProductCatalog')),
};
const Account = {
	Profile: lazy(() => import('../views/sodiq/Profile')),
};

const Transporter = {
	ReviewDocument: lazy(() => import('../views/rio/ReviewDocument')),
	AdminTransporter: lazy(() => import('../views/bakti/admin-transporter/AdminTransporter')),
};

const Material = {
	RequestMaterial: lazy(() => import('../views/bakti/request-material/RequestMaterial')),
	MaterialCatalog: lazy(() => import('../views/bakti/material-catalog/MaterialCatalog')),
	ApprovalSample: lazy(() => import('../views/bakti/approval-sample/ApprovalSample')),
};

const FAT = {
	ListDelivery: lazy(() => import('../views/aji/ListDelivery')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Invoice.InvoiceVendor />,
		index: 'InvoiceVendor',
	},
	// Eko Routers
	{
		path: null,
		element: <DUMMY.PAYMENT_REMARK />,
		index: 'PAYMENT_REMARK',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.TAX_AP />,
		index: 'INV_TAX_APPROVAL',
		exact: true,
	},
	// Afif Routes
	{
		path: null,
		element: <DUMMY.PPN />,
		index: 'PPN',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.INV_AP_APPROVAL />,
		index: 'INV_AP_APPROVAL',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.INV_DOC_RECEIPT />,
		index: 'INV_DOC_RECEIPT',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.SAMPLE_REQUEST />,
		index: 'SAMPLE_REQUEST',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.AUTHORIZATION />,
		index: 'authorization',
		exact: true,
	},
	// Rizky Routes
	{
		path: null,
		element: <DUMMY.Category />,
		index: 'Category',
		exact: true,
	},
	{
		path: null,
		element: <DUMMY.Payment />,
		index: 'Payment',
		exact: true,
	},
	{
		path: null,
		element: <Master.Service />,
		index: 'Service',
		exact: true,
	},
	{
		path: null,
		element: <Master.Instruction />,
		index: 'Instruction',
		exact: true,
	},
	{
		path: null,
		element: <Master.InstructionVendor />,
		index: 'InstructionVendor',
		exact: true,
	},
	{
		path: null,
		element: <Product.ProductCatalog />,
		index: 'ProductCatalog',
		exact: true,
	},
	{
		path: null,
		element: <Account.Profile />,
		index: 'Profile',
		exact: true,
	},
	{
		path: null,
		element: <Material.RequestMaterial />,
		index: 'RNDRequestMaterial',
		exact: true,
	},
	{
		path: null,
		element: <Master.InstructionProductListing />,
		index: 'InstructionProductListing',
		exact: true,
	},
	{
		path: null,
		element: <Material.MaterialCatalog />,
		index: 'PRCMaterialCatalog',
		exact: true,
	},
	{
		path: null,
		element: <Material.ApprovalSample />,
		index: 'PRCApprovalSample',
		exact: true,
	},
	{
		path: null,
		element: <Transporter.ReviewDocument />,
		index: 'ReviewDocument',
		exact: true,
	},
	{
		path: null,
		element: <Transporter.AdminTransporter />,
		index: 'AdminTransporter',
		exact: true,
	},
	{
		path: null,
		element: <FAT.ListDelivery />,
		index: 'ListDelivery',
		exact: true,
	},
];
export default contents;
